<template>
  <div class="card text-center">
    <router-link :to="{ path: this.dynamicContent.metadata.link }">
      <img
        v-lazy="
          this.dynamicContent.metadata.image.imgix_url +
          '?w=290&h=180&fit=clamp&auto=mode'
        "
        alt="Day Highlights"
        width="290px"
        height="180px"
        class="card-animation-on-hover"
        style="
          margin-top: -10px;
          opacity: 0.8;
          z-index: 12;
          box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 15px 0px;
        "
      />
    </router-link>
    <div
      class="text-center"
      style="margin-top: 10px; padding: 1px"
      v-html="todayParties"
    ></div>
  </div>
</template>
<script>
import moment from "moment";
import {venueDecoder} from "@/common/Decoder";

export default {
  name: 'ClubActivity',
  created() {
    this.$store.dispatch('getDynamicContent');
  },
  computed: {
    todayParties: {
      get() {
        function todayParties(x) {

          let partyDateEndOfDay = moment(x.metadata.date + 'T' + x.metadata.start_time + "-05:00" ).endOf('day').format("MMM Do ");
          let now = moment().startOf('day').format("MMM Do ");
          return partyDateEndOfDay === now;
        }

        let parties = this.$store.state.calendar.filter((party) =>
          todayParties(party)
        ).map(todayParty =>
          `<div>
             <strong>${todayParty.metadata.start_time}</strong> | ${todayParty.title.split('@')[0]} @ <a href='${venueDecoder(todayParty.title).slug || '/los-cabos-clubs' }'}'>
               ${venueDecoder(todayParty.title).title}
           </a></div>`).join("");
        return `<h3 class="tulum-party-subtitle" style="margin-bottom: 0px">${moment().format("MMM Do ")}</h3>    <small >What to do?</small><br><br>
` + parties;
      },
    },
    dynamicContent: {
      get() {
        return this.$store.state.dynamicContent;
      },
    },
  },
};
</script>
