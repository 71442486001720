<template>
  <div class="content" style="margin-top: -50px">
    <vue-whatsapp-widget
      phoneNumber="+524421177251"
      companyName="cabo.party (Marissa)"
      textReply="Typically replies within 10-15 mins (9am - 10pm)"
      companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
    />

    <div class="header text-center" style="margin-bottom: -35px">
      <HomeMainImage/>
      <br/>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card style="z-index: 1; text-align: center; padding: 10px">
          <template slot="header">
            <p style="font-size: 16px">



              Los Cabos Mexico, <a href="https://en.wikipedia.org/wiki/Cabo_San_Lucas">- Cabo San Lucas</a> & San Jose Cabo- is where you want to be if you
              are looking for a coastal paradise with endless options for fun. With exclusive               <router-link :to="{path:'/los-cabos-clubs'}">clubs</router-link>, vibrant parties & unique flavored nightlife experience .
              <br>
            </p>
            <hr>

            <p
              style="font-size: 14px;color: #3ea2c1 !important;letter-spacing: 0.2rem;;padding: 1px 60px 2px 60px">
              A surreal location with exclusive
              <router-link :to="{path: '/los-cabos-clubs'}">clubs</router-link>
              , resorts & top reputation
              <router-link :to="{path: '/article/article-the-nightlife-guide-of-los-cabos'}">nightlife</router-link>
              .
              <br>
              <br>
              <br>
            </p>

          </template>

        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-8 offset-md-2">
        <card
          class="card"
          style="
            z-index: 1;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            margin-top: -80px;
            background-color: #151414;
            padding: 15px;
          "
        >
          <template slot="header">
            <br/>
            <ClubActivity></ClubActivity>
          </template>
          <slot>
            <div class="text-center">
              <RsvpViaDialog></RsvpViaDialog>
              <br/>
              <br/>

              <br/>
              <a href="mailto:bookings@cabo.party">bookings@cabo.party</a>

              <br/>

              <small> We operate from 9AM - 10PM / Answer within 15 mins</small>
              <br/>
              <br/>
              <small style="color: #fff; font-weight: bolder">NO EXTRA FEE</small
              >
              <br/>
              <small>Show your love, reserve through us.</small>

              <br/>
            </div>
          </slot>
        </card>
        <div
          class="card text-center "
          style="z-index: 1; background-color: #151414;padding: 20px"
        >
          <h3 class="tulum-party-title" style="margin-bottom: 0;font-size: 2.5rem">
            CABO CLUBS
          </h3>
          <small style="font-weight: bold" class="tulum-party-subtitle-bg">
            We take you into the Cabo's Elite Club Scene
          </small>
          <br>
          <img v-lazy="clubsSpirt" width="214px" height="59px" alt="top cabo clubs" class="centered">

          <br>

          <router-link to="/los-cabos-clubs" class="btn btn-primary">
            TOP CLUBS
          </router-link>

        </div>
      </div>
    </div>

    <div class="col-md-12 text-center" style="z-index: 1; background-color: rgb(35 32 39)"
    >
      <br/>
      <h2 class="title tulum-party-title section-header" style="margin-bottom: 0;font-weight: bold">
        EVENT LINE
      </h2>
      <br/>
    </div>
    <section
      class="text-center"
      style="background: linear-gradient(#30abc6, black)"
    >


      <h3 class="section-header text-left">UPCOMING EVENTS</h3>
      <div
        v-for="(event, idx) in incomingParties.filter(
          (x) => !x.metadata.magicEvent
        )"
        :key="event._id"
        :full="true"
        :inverted="idx % 2 === 1"
        :badge-type="badgeType(idx)"
      >
        <div slot="content" class="card-link">
            <EventCard
              :loading="event.loading"
              v-bind:event="event"
              :key="event._id"
              :idx="idx"
            ></EventCard>


            <!--              <MiniPartyCard-->
            <!--                v-if="event.metadata.magicEvent"-->
            <!--                :event="event"-->
            <!--                large="true"-->
            <!--                withVenue="true"-->
            <!--                :key="event.title"-->
            <!--              ></MiniPartyCard>-->
          </div>


      </div>
    </section>
    <section
      class="text-center"
      style="background: linear-gradient(#30abc6, black)"
    >


      <SmartMenu withRsvp="false" :links="links">
        <p>Unlock Los Cabos Magic - Secure your spot with us now!</p>
      </SmartMenu>
    </section>

  </div>
</template>
<script>
import EventCard from '../../components/EventCard/EventCard';
// import moment from 'moment';
import ClubActivity from '@/components/ClubActivity/ClubActivity';
import HomeMainImage from '@/pages/Home/HomeMainImage';
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog";
import SmartMenu from "@/components/SmartMenu/SmartMenu";

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );
// const DynamicCard = () => import('@/components/Cards/DynamicCard');

export default {
  name: 'Home',
  created: function () {
   this.$store.dispatch('getAllParties');
   this.$store.dispatch('getLinks', {slug: 'los-cabos-mexico-event-calendar'});

  },
  metaInfo() {
    return {
      title:
        'CABO PARTY · Los Cabos | Top Events & Clubs',
      // link: [{rel: 'preload', as: 'image',
      //   href: this.mainImage}],
      meta: [
        {
          name: 'description',
          content:
            'CABO.PARTY - We put together the best events happening around Cabo San Lucas. Tickets and Reservations for the best parties & clubs. Join the largest party community',
        },
        {
          name: 'keywords',
          content: 'CABO, Parties, Party, DJ, Techno',
        },
        {
          property: 'og:site_name',
          content: `CABO PARTY`,
        },
        {
          property: 'og:title',
          content: `CABO PARTY: Parties, Nightlife & Techno Music. Top Events & Clubs In Cabo San Lucas`,
        },
        {property: 'og:type', content: 'website'},
        {
          property: 'og:description',
          content: `CABO.PARTY - We put together the best events happening around Cabo San Lucas. Tickets and Reservations for the best parties & clubs. Recommendations and reviews.`,
        },
        {property: 'og:locality', content: 'Cabo San Lucas'},
        {property: 'og:country-name', content: 'Mexico'},
        {
          property: 'og:image',
          content: this.mainImage,
        },
      ],
    };
  },
  data: () => {
    return {
      modal: {},
      showingAll: false,
      events: [],
      showSearch: true,
      contactNumber: '524421177251',
      cabo: "https://imgix.cosmicjs.com/d9751ff0-f637-11ee-b555-0d0678c27dd7-los-cabos-drawing.png",
      clubsSpirt:
        'https://imgix.cosmicjs.com/7a8a8c30-0d36-11ef-9eca-7d347081a9fb-clubs-index.jpg',
      calendarImg:
        'https://imgix.cosmicjs.com/00c12a20-7d7c-11ee-9baf-2960bf73c40e-image.png',
      instagram:
        'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      mainImage:
        'https://imgix.cosmicjs.com/1eb5e990-f3e6-11ee-b555-0d0678c27dd7-Bits-of-color.jpg',
    };
  },
  components: {
    SmartMenu,
    RsvpViaDialog,
    EventCard,
    HomeMainImage,
    ClubActivity,
    VueWhatsappWidget,
  },
  methods: {
    onQuickReserveClick() {
      this.$gtag.event('reservations', {
        value: 20,
        event_category: 'reservations',
        event_label: 'PartySupport' });
      this.$rollbar.info('PartySupport');
    },
    showMore(id) {
      this.modal = {};
      this.modal[id] = true;
    },
    showLess(id) {
      this.modal = {};
      this.modal[id] = false;
    },
    goToParty(event) {
      this.$rollbar.info('QUICK PARTY: ' + event.target.value);
      if (event.target.value === 'calendar') {
        this.$router.push({
          path: '/los-cabos-mexico-event-calendar',
          replace: true,
        });
        return;
      }
      if (event.target.value === 'zamna') {
        this.$router.push({path: '/brands/zamna-tulum', replace: true});
        return;
      }
      this.$router.push({
        path: '/event/' + event.target.value,
        replace: true,
      });
    },
    formatDate(event) {
      let date = new Date(event.metadata.date).toISOString();
      return `${date.split('T')[0].split('-')[1]}/${
        date.split('T')[0].split('-')[2]
      } >> ${event.title.split('@')[0]}`;
    },
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },

  computed: {
    partiesTotal: {
      get() {
        return this.$store.state.parties_total;
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    incomingParties: {
      get() {
        return this.$store.state.calendar;
      },
    },
  },
};
</script>
