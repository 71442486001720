<template>
  <div>
    <div style="margin-top: 35px">
      <img
        height="200px"
        width="280px"
        v-if="imgIdx < 0"
        v-lazy="caboImg + '?h=200&w=280&auto=format&fit=clamp&fill=blur'"
        alt="tulum.party"
        class="d-inline-xs d-sm-none"
        style="
          z-index: -2;
          box-shadow: 0px 20px 25px 0px black;
          border-radius: 0;
        "
      />

      <img
        height="260px"
        width="600px"
        v-if="imgIdx < 0"
        v-lazy="caboImg + '?h=260&w=600&auto=format&fit=clamp&fill=blur'"
        alt="tulum.party"
        class="d-none d-sm-inline"
        style="
          z-index: 12;
          box-shadow: 0px 20px 25px 0px black;
          border-radius: 0;
        "
      />
      <h1 class="tulum-party-title" style="background-color: #124956 !important; font-size: 2.4rem;font-weight: bold;margin-top: -20px">CABO PARTY</h1>
      <h3
        class="tulum-party-subtitle"
        style="
          margin-top: 1px;
          text-transform: uppercase;
          font-size: 0.7rem;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          max-width: 350px;
          letter-spacing: 0.3rem;
          font-weight: normal;
          color: #30abc6 !important;
        "
      >
        Los Cabos  <br>Event, Clubs & Parties Hub
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeMainImage',
  data: () => {
    return {
      logo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
      caboImg:
        'https://imgix.cosmicjs.com/1eb5e990-f3e6-11ee-b555-0d0678c27dd7-Bits-of-color.jpg',
      miniLogo:
        'https://imgix.cosmicjs.com/57a1ab60-a461-11ee-b417-db331415685f-tpicon.png',
      marcianitos:
        'https://imgix.cosmicjs.com/dbcd2d20-afde-11ec-97bc-19d12908cbbe-image.png',
      mainImage:
        'https://imgix.cosmicjs.com/e33aedb0-9c2f-11ee-a141-bf415eaf0a74-buzo-verde-2.png',
      imgIdx: -1,
    };
  },
};
</script>
